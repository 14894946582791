const defaultState = {
    work: []
}
// Добавление рабочего документа в store
const ADD_WORK = "ADD_WORK"
// Удаление рабочего документа из store
const UPDATE_WORK = "UPDATE_WORK"

const REMOVE_WORK = "REMOVE_WORK"

export const takenToWorkReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_WORK:
            // Обновим позицию в store по guid
            let guid = action.payload.guid

            let newOrderItems = []

            state.work.order.map(itemData => {
                    if (itemData.item.guid === guid)
                    {
                        newOrderItems.push({
                            item: {
                                guid: itemData.item.guid,
                                name: itemData.item.name,
                                keyLine: itemData.item.keyLine,
                                keyLineZP: itemData.item.keyLineZP,
                            },
                            quantity: Number(action.payload.newNum),
                            unit: itemData.unit,
                            quantitySupply: itemData.quantitySupply,
                            quantityConsum: itemData.quantityConsum,
                            total: itemData.total,
                        })
                    } else {
                        newOrderItems.push(itemData)
                    }
                });

            let newDocument = {
                id: state.work.id,
                number: state.work.number,
                slug: state.work.slug,
                // - Локация
                location: state.work.location,
                object: state.work.object,
                // - Статус проведения
                final: state.work.final,
                // - Статус отправки
                send: state.work.send,
                // - Форматированная дата
                date: state.work.date,
                // - Информация о документе
                doc_info: state.work.doc_info,
                // - Массив данных о перемещении
                order: newOrderItems
            }

            return {...state, work: newDocument}
        case ADD_WORK:
            let arrayOrder = [];
            // Перед добавлением документа, установим значения текущих полей согласно принятым документам
            action.payload.order.map(item => {
                arrayOrder.push({
                    item: {
                        guid: item.item.guid,
                        name: item.item.name,
                        keyLine: item.item.keyLine,
                        keyLineZP: item.item.keyLineZP,
                    },
                    quantity: Number(item.total) - Number(item.quantitySupply),
                    unit: item.unit,
                    quantitySupply: item.quantitySupply,
                    quantityConsum: item.quantityConsum,
                    total: item.total,
                });
            });

            let setDocumentData = {
                id: action.payload.id,
                number: action.payload.number,
                slug: action.payload.slug,
                // - Локация
                location: action.payload.location,
                object: action.payload.object,
                // - Статус проведения
                final: action.payload.final,
                // - Статус отправки
                send: action.payload.send,
                // - Форматированная дата
                date: action.payload.date,
                // - Информация о документе
                doc_info: action.payload.doc_info,
                // - Массив данных о перемещении
                order: arrayOrder
            }

            return {...state, work: setDocumentData}
        case REMOVE_WORK:
            return {...state, work: {}}
        default:
            return state
    }
}

export const addTakenToWorkAction = (payload) => ({type: ADD_WORK, payload})
export const updateTakenToWorkAction = (payload) => ({type: UPDATE_WORK, payload})
export const removeTakenToWorkAction = (payload) => ({type: REMOVE_WORK, payload})
